<template>
  <div
    class="v-stages v-scrollbar"
    :class="{ 'v-stages-left': stagesXPosition === 'left' }"
  >
    <div
      v-for="(stage, index) in items"
      :key="`stage-${uid}-${index}`"
      class="v-stages__stage"
      :class="{
        'v-stages__stage--active': index <= activeIndex,
        'v-stages__stage--current': activeIndex === index
      }"
    >
      <div class="v-stages__stage-content">
        <slot
          name="stage"
          v-bind="stage"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
withDefaults(
  defineProps<{
    items: T[]
    activeIndex: number
    stagesXPosition?: 'center' | 'left'
  }>(),
  {
    stagesXPosition: 'center'
  }
)

const uid = useId()
</script>

<style lang="scss">
@use '~/assets/variables';

.v-stages {
  display: flex;
  justify-content: space-between;
  overflow: auto;
  padding-bottom: 1.1rem;

  &.v-stages-left {
    .v-stages__stage {
      align-items: start;
      text-align: left;

      &::before {
        margin-left: 0;
      }

      &::after {
        transform: translateX(-100%);
      }

      &:nth-child(n + 2) {
        .v-stages__stage-content {
          text-align: center;
          transform: translateX(calc(-50% + 10px));
        }
      }
    }
  }

  &__stage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    min-width: 70px;
    text-align: center;

    &::before {
      content: '';
      position: relative;
      display: block;
      margin: 0 auto 10px;
      width: 12px;
      height: 12px;
      border: 3px solid variables.$BodyTextColorLight;
      background: variables.$BodyTextColorLight;
      border-radius: 16px;
      z-index: 1;
    }

    &:nth-child(n + 2) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        transform: translateX(-50%);
        width: calc(100% - 6px);
        height: 2px;
        background: variables.$BodyTextColorLight;
      }
    }

    &--active {
      &::before {
        background: variables.$BodyElementsBackground;
        border-color: variables.$SecondaryBackgroundColor;
      }

      &:nth-child(n + 2)::after {
        background: variables.$SecondaryBackgroundColor;
      }
    }

    &--current::before {
      background: variables.$SecondaryBackgroundColor;
      border-color: variables.$SecondaryBackgroundColor;
    }
  }
}
</style>
