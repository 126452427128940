<i18n>
ru:
  cashbackNextLevel: 'Для возврата баллами {nextCashback} от суммы заказа закажите еще на'
  congratulation: 'Поздравляем, Ваш кешбэк максимален!'
  continue: Продолжить
  currentCashback: Ваш кешбэк составляет
  howToGetBonusesQuestion: Как получить бонусные баллы?
  howToGetBonusesText: >-
    Войдите в личный кабинет и совершайте заказы. Чем больше заказов Вы
    совершаете, будучи авторизованными, тем больше баллов Вам возвращается.
  opportunityToBuyDishesByBonuses: Теперь можно оплачивать любимые блюда бонусными баллами!
  title: Кешбэк
  wish: Приятных заказов!
ua:
  cashbackNextLevel: 'Для повернення балами {nextCashback} від суми замовлення замовте ще на'
  congratulation: 'Вітаємо, Ваш кешбек максимальний!'
  continue: Продовжити
  currentCashback: Ваш кешбэк складає
  howToGetBonusesQuestion: Як отримати бонусні бали?
  howToGetBonusesText: >-
    Увійдіть до особистого кабінету та виконуйте замовлення. Чим більше
    замовлень Ви робите, будучи авторизованими, тим більше балів Вам
    повертається.
  opportunityToBuyDishesByBonuses: Тепер можна сплачувати улюблені страви бонусними балами!
  title: Кешбэк
  wish: Приємних замовлень!
us:
  cashbackNextLevel: 'To return {nextCashback} of the order amount with points, order another'
  congratulation: 'Congratulations, your cashback is maximum!'
  continue: Continue
  currentCashback: Your cashback is
  howToGetBonusesQuestion: How to get bonus points?
  howToGetBonusesText: >-
    Log in to your personal account and place orders. The more orders you make
    while logged in, the more points you get back.
  opportunityToBuyDishesByBonuses: Now you can pay for your favorite dishes with bonus points!
  title: Cashback
  wish: Happy orders!
</i18n>

<template>
  <div class="v-popup-head v-title">
    <div
      class="v-popup-ranks--title v-font-weight-500"
      v-html="translate('ranksPopup.title')"
    />
  </div>
  <div class="v-popup-body">
    <template v-if="!isMaximumRank">
      <div class="v-mb-xs v-d-flex">
        <span
          class="v-mr-xxs"
          v-html="translate('ranksPopup.currentCashback')"
        />
        <b>{{ accountStore.CurrentRank?.Status?.Title ?? '' }} </b>
      </div>
      <div class="v-mb-sm">
        <span
          v-html="
            translate('ranksPopup.cashbackNextLevel', {
              nextCashback: nextCashback.title
            })
          "
        />
        <common-currency
          class="v-font-weight-600 v-ml-xs"
          :amount="nextCashback.moneyLeft"
        />
      </div>
      <common-stages
        class="v-mb-sm"
        :active-index="activeRankIndex"
        :items="accountStore.RanksStat.data?.Ranks ?? []"
        stages-x-position="left"
      >
        <template #stage="rank: Rank">
          <div class="v-d-flex v-flex-column">
            <span
              class="v-mb-sm"
              :class="{ 'v-font-weight-500': rank.ID === accountStore.CurrentRank?.Status?.ID }"
              v-html="sanitize(rank.Title)"
            />
            <common-currency
              class="v-user-ranks-benchmark v-align-currency-center"
              :amount="rank.Benchmark"
            />
          </div>
        </template>
      </common-stages>
      <div
        class="v-mb-sm"
        v-html="translate('ranksPopup.opportunityToBuyDishesByBonuses')"
      />
      <div
        class="v-mb-xs v-font-weight-500 v-user-ranks-bonuses-title"
        v-html="translate('ranksPopup.howToGetBonusesQuestion')"
      />
      <div
        class="v-mb-xs"
        v-html="translate('ranksPopup.howToGetBonusesText')"
      />
      <div
        class="v-font-weight-500"
        v-html="translate('ranksPopup.wish')"
      />
    </template>
    <template v-else>
      <div v-html="translate('ranksPopup.congratulation')" />
    </template>
  </div>
  <div class="v-d-flex v-flex-end">
    <arora-button
      :label="translate('ranksPopup.continue')"
      @click="async () => await popupStore.closePopup()"
    />
  </div>
</template>

<script setup lang="ts">
import type { Rank } from '~types/accountStore'

const accountStore = useAccountStore()
const popupStore = usePopupStore()
const { sanitize, translate } = useI18nSanitized()

const isMaximumRank = ref<boolean>(false)
const activeRankIndex = ref<number>(0)
const nextCashback = ref<{ title: string; moneyLeft: number }>({ title: '', moneyLeft: 0 })

onMounted(async () => {
  await accountStore.initRanks()

  const lastRank: Rank | undefined =
    accountStore.RanksStat.data?.Ranks[accountStore.RanksStat.data?.Ranks.length - 1]

  isMaximumRank.value = accountStore.CurrentRank?.Status?.ID === lastRank?.ID
  activeRankIndex.value = (accountStore.RanksStat.data?.Ranks ?? []).findIndex(
    (rank) => rank.ID === accountStore.CurrentRank?.Status?.ID
  )

  const nextRank = accountStore.RanksStat.data?.Ranks[activeRankIndex.value + 1]
  if (!isMaximumRank.value && nextRank) {
    nextCashback.value.title = nextRank.Title
    nextCashback.value.moneyLeft = nextRank.Benchmark - accountStore.CurrentRank?.Sum
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-popup-account-ranks-popup {
  .v-popup-head {
    .v-popup-ranks--title {
      font-size: variables.$TextSizeH3;
    }
  }

  .v-popup-body {
    margin-bottom: 30px;

    .v-user-ranks-benchmark {
      font-size: 0.8rem;
      color: variables.$BodyTextColorLight;
      gap: 1px;
      flex-wrap: nowrap;
    }

    .v-user-ranks-bonuses-title {
      font-size: 1.2rem;
    }
  }
}
</style>
